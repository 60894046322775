import React from 'react';
import {useState} from 'react';
export default function FooterSubcategory({category}) {
	const [showMe, setShowMe] = useState(false);
	function toggle() {
		setShowMe(!showMe);
	}
    const htmlDecode = (input) => {
		var e = document.createElement('textarea');
		e.innerHTML = input;
		// handle case of empty input
		return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
	};
	return (
		<div>
			<h2 className="font-semibold mb-4 text-primary text-[16px] sm:block flex justify-between" onClick={toggle}>
				<span>{htmlDecode(category.attributes.name)}</span>
				<i className="fas fa-chevron-down block sm:hidden"></i>
			</h2>
			<div
				style={{
					display: showMe ? 'block' : 'none',
				}}
				className="sm:block hidden footer-menu">
				{category.attributes.subcat_id &&
					category.attributes.subcat_id.data !== null &&
					category.attributes.subcat_id.data.map((subcategory, index1) => {
						return (
							<a key={index1} className="mb-2 text-white text-sm block">
								{htmlDecode(subcategory.attributes.name)}{' '}
							</a>
						);
					})}
			</div>
		</div>
	);
}
