
import Image from 'next/image';
import Link from 'next/link';
import Logo from '../images/logo.png';
import { useEffect, useState } from 'react';
import { getJwt } from '../utils/handle-session';
import { getUser, destroyUser, getUserData } from '../utils/handle-session';
const Navbar = () => {
	const [showMe, setShowMe] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState({});
	function toggle() {
		setShowMe(!showMe);
	}
	const GetUserData = async () => {
		const user = getUser();
		const jwt = getJwt();
		if (user && jwt) {
			const userdata = JSON.parse(user);
			const User = await getUserData(userdata.id);
			setIsLoggedIn(true);
			setUser(User);

		}
		setLoading(false);
	}
	useEffect(() => {
		GetUserData();
	}, [])
	const handleLogout = () => {
		destroyUser();
		window.location.href = '/';
	};
	const myLoader = ({ src }) => {
		return `${src}`;
	};
	return (
		<>
			{!isLoading &&
				<header className='bg-black w-full py-1 relative z-[10000]' id="courseMenu">
					<div className='innerDiv mx-auto px-4 zeroVerticalPad py-0 '>

						<nav className='flex items-center justify-between flex-wrap relative'>
							<div className='flex items-center flex-shrink-0 text-white pt-[5px] logo-img'>
								<Link passHref href='/'>
									<a><Image src={Logo} alt='' width={130} height={45} /></a>
								</Link>
							</div>
							<div className='flex-grow flex items-center w-auto justify-end none'>
								{!isLoggedIn ?
									<a onClick={() => {
										window.location.href = 'https://login.gurucool.xyz?last_url=' + window.location.href;
									}} className='bg-primary inline-block rounded xl:px-12 lg:px-8 md:px-6 px-4 sm:py-2.5 py-2 font-semibold sm:text-base text-sm'>Login</a>
									: <div className='relative'>
										<button className='flex items-center' onClick={toggle}>
											<Image loader={myLoader} src={`${user.attributes.avatar.data !== null
												? user.attributes.avatar.data.attributes.url : '/man.svg'}`} alt='' width={56} height={56} className='rounded-full' /> <span className='ml-3 sm:block hidden text-white font-medium'>
												{user.attributes.first_name != null
													? user.attributes.first_name + ' ' + user.attributes.last_name
													: user.attributes.user_id.data.attributes.username}
											</span>
										</button>
										<ul
											style={{
												display: showMe ? 'block' : 'none',
											}}
											className='dropdown-menu absolute bg-white top-full rounded-md shadow-lg sm:w-80 w-64 z-[99] right-0 top-[100%]'>

											<li className='block'>
												<ul>
													<li className='py-3 px-4 border-b border-b-gray-300 mb-4'>
														<a className='flex items-center mb-3'>
															<Image loader={myLoader} src={`${user.attributes.avatar.data !== null
																? user.attributes.avatar.data.attributes.url : '/man.svg'}`} alt='' width={56} height={56} className='rounded-full' />
															<span className='ml-3 text-lg font-medium'>
																{user.attributes.first_name != null
																	? user.attributes.first_name + ' ' + user.attributes.last_name
																	: user.attributes.user_id.data.attributes.username}
															</span>
														</a>

													</li>
													<li className='mb-3'>
														<a href='https://padhaai.gurucool.xyz/profile' className='flex px-4 py-2 justify-between items-center'>
															<span>
																<i className='fas fa-user'></i> <span className='ml-2'>View Profile</span>
															</span>
															<i className='fas fa-chevron-right'></i>
														</a>
													</li>
													<li className='mb-3'>
														<a href='https://padhaai.gurucool.xyz/editprofile' className='flex px-4 py-2 justify-between items-center'>
															<span>
																<i className='far fa-pen-to-square'></i> <span className='ml-2'>Edit Profile</span>
															</span>
															<i className='fas fa-chevron-right'></i>
														</a>
													</li>
													<li className='mb-3'>
														<button className='flex w-full px-4 py-2 justify-between items-center' onClick={handleLogout}>
															<span>
																<i className='fas fa-arrow-right-from-bracket'></i> <span className='ml-2'>Log Out</span>
															</span>
															<i className='fas fa-chevron-right'></i>
														</button>
													</li>
												</ul>
											</li>

										</ul>
									</div>}


							</div>
						</nav>

					</div>
				</header>
			}
		</>
	);
};

export default Navbar;
