/** @format */

import Image from 'next/image';
import Logo from '../images/logo2.png';
import {useEffect, useState} from 'react';
import {strapi_end_point} from '../config';
const qs = require('qs');
import FooterSubcategory from './FooterSubcategory'
const Footer = () => {
	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const GetData = async () => {
		const query = qs.stringify(
			{
				sort: ['id:asc'],
				fields: ['id', 'slug', 'name'],
				populate: {
					subcat_id: {
						fields: ['id', 'slug', 'name'],
					},
				},
				pagination: {
					pageSize: 6,
					page: 1,
				},
				publicationState: 'live',
				locale: ['en'],
			},
			{
				encodeValuesOnly: true, // prettify url
			}
		);
		const Response = await fetch(`${strapi_end_point}/api/course-categories?${query}`);
		const Data = await Response.json();
		setData(Data.data);
		setLoading(!isLoading);
	};
	useEffect(() => {
		GetData();
	}, []);
	
	
	return (
		<>
			<footer className="bg-black pt-[60px] pb-[60px] sm:pt-[100px] sm:pb-[100px] lg:pt-[120px] lg:pb-[120px]">
				<div className="innerDiv">
					<div className="flex xl:gap-12  gap-10 mb-8 flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap  ">
						{!isLoading &&
							data.map((category, index) => {
								return (
									<FooterSubcategory  key={index} category={category} />
									
										
									
								);
							})}
					</div>
					<div className="flex flex-col justify-between items-center sm:flex-row">
						<div>
							<Image alt="" src={Logo}></Image>
						</div>
						<p className="text-white">2022 Gurucool XYZ PVT LTD</p>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
